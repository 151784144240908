import React, { useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { useAppSelector, useAppDispatch } from "store";

import { routes } from "routes";

import {
  changeSelectedDropdownItem,
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setDropdownMenuContent,
  setListView,
  setShowDeleteConfirmation,
  updateAnalysisListSorting,
  changeListView,
} from "store/analysis/analysisListSlice";
import {
  setImportModalErrorFallbackAnalysisId,
  setImportModalFileId,
  setImportModalOpen,
} from "store/analysisSettings/dataSourceSlice";
import { deleteSelectedAnalysis, getAnalyses, getMyAnalysesCount } from "store/analysis/thunks";
import { setSearchTerm } from "store/search/searchbarSlice";
import { showToastError } from "store/toast/toastSlice";
import { useResource } from "hooks/useResource";
import useTableSize from "hooks/useTableSize";
import { uploadFile } from "services/analysis";
import { analysisJobInProgress } from "utils/analysisJobInProgress";
import { getLocalTime } from "utils/getLocalTime";
import { isAnalyzed } from "utils/isAnalyzed";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { ViewSelection } from "./_layouts/ViewSelection";
import { ImportDataButton } from "pages/analysis/_layouts/ImportDataButton";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";

import { TableInformation } from "components/Table/ClickableTableHeader";
import { Pagination } from "components/Pagination";
import { SearchRow } from "components/SearchRow";
import { EmptyState } from "components/EmptyState";
import { StyledTablePageHeader } from "components/Table/TablePageHeader";
import { FileUploadInput } from "components/_inputs/FileUpload";
import { ErrorScreen } from "components/ErrorScreen";
import { LoadingDots } from "components/LoadingDots";
import { Icon, IconType } from "components/_icons/Icon";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { CategorizationTypeInfo } from "components/CategorizationTypeInfo";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { DataSourceModal } from "common-layouts/DataSourceModal";
import { FeatureFlag } from "components/FeatureFlag";

import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import {
  AnalysisListView,
  SocketEvent,
  RoleType,
  AnalysisStatus,
  FileUploadStatus,
  PermissionLevel,
} from "@explorance/mly-types";
import { AnalysisSortingParameter } from "ts/enums/sorting";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { Color } from "ts/enums/color";
import { PageErrorType } from "ts/enums/pageErrorType";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { DropdownMenuItem } from "ts/dropdown";
import { FlexTableSize, FlexTableType } from "ts/enums/flexTable";
import noAnalysisShared from "assets/images/no-shared-users.svg";
import emptyStateImage from "assets/images/empty-analysis.svg";
import { Analysis } from "ts/analysis";
import { Feature } from "ts/enums/feature";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";

export const AnalysisListPage = () => {
  // Redux states
  const state = useAppSelector((state) => state.analysisList);
  const { currentUser } = useAppSelector((state) => state.auth);
  const { importModalFileId } = useAppSelector((state) => state.dataSource);

  // Hooks
  const dispatch = useAppDispatch();
  const socket = useAppSelector((state) => state.wsStream.socket);
  const history = useHistory();
  const location = history.location;
  const { getResource } = useResource();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const tableSize = useTableSize();

  // Refs
  const inputFileRef = useRef<HTMLInputElement>();
  const retryFailedImportInputRef = useRef<HTMLInputElement>();

  // Local consts
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const isAnalyst = currentUser.roleType === RoleType.Analyst;
  const isSharedWithMeView = state.listView === AnalysisListView.SharedWithMe;

  const tableInformation: TableInformation<AnalysisSortingParameter>[] = Object.values(
    AnalysisSortingParameter
  )
    .filter((asp) => {
      if (tableSize === FlexTableSize.Limited && state.listView !== AnalysisListView.MyAnalysis) {
        return ![
          AnalysisSortingParameter.TopicInsightCount,
          AnalysisSortingParameter.AlertCount,
          AnalysisSortingParameter.RecommendationCount,
        ].includes(asp);
      } else if (
        tableSize === FlexTableSize.Limited &&
        state.listView === AnalysisListView.MyAnalysis
      ) {
        return ![
          AnalysisSortingParameter.TopicInsightCount,
          AnalysisSortingParameter.AlertCount,
          AnalysisSortingParameter.RecommendationCount,
          AnalysisSortingParameter.AnalysisOwner,
        ].includes(asp);
      } else if (state.listView === AnalysisListView.MyAnalysis) {
        return ![AnalysisSortingParameter.AnalysisOwner].includes(asp);
      }
      return true;
    })
    .map((a) => ({
      headerName: <Text resource={`analysisList.table.${a}`} />,
      sortingParameter: a,
    }));

  const onDoubleClickRow = (id: number) => {
    const analysis = state.analysisList.find((rowItem) => rowItem.analysisId === id);
    dispatch(setImportModalFileId(analysis.fileId));
    dispatch(setImportModalErrorFallbackAnalysisId(id));
    if (analysis.analysisStatus === AnalysisStatus.Completed) {
      history.push(routes.overviewPage(id));
    } else if (analysis.fileUploadStatus !== FileUploadStatus.Completed) {
      dispatch(setImportModalOpen(true));
    } else {
      history.push(routes.settingsPage(id));
    }
  };

  const getColumnWidths = () => {
    if (state.listView === AnalysisListView.MyAnalysis) {
      return tableSize === FlexTableSize.Full
        ? ["22%", "15%", "12%", "12%", "15%", "10%", "10%", "4%"]
        : ["35%", "24%", "24%", "14%", "3%"];
    } else {
      return tableSize === FlexTableSize.Full
        ? ["17%", "10%", "12%", "12%", "12%", "14%", "8%", "12%", "3%"]
        : ["20%", "20%", "20%", "15%", "20%", "5%"];
    }
  };

  const getDotsMenuContent = (analysis: Analysis): DropdownMenuItem[] => {
    const isSharingViewer = analysis.sharingProperties?.permissionLevel === PermissionLevel.View;
    const isAnalysisOwner = analysis.owner === `${currentUser.firstname} ${currentUser.lastname}`;

    return [
      {
        label: <Text resource="analysisList.table.dropdown.viewImportProgress" />,
        onClick: () => {
          dispatch(setImportModalFileId(analysis.fileId));
          dispatch(setImportModalOpen(true));
        },
        show: analysis.fileUploadStatus === FileUploadStatus.Uploading,
      },
      {
        label: <Text resource="analysisList.table.dropdown.viewAnalysis" />,
        href: routes.overviewPage(analysis.analysisId),
        show: true,
        isDisabled:
          (analysis.fileUploadStatus === FileUploadStatus.Failed &&
            analysis.analysisStatus === AnalysisStatus.Failed) ||
          analysis.analysisStatus === AnalysisStatus.Failed ||
          analysis.analysisStatus === AnalysisStatus.NotAnalyzed ||
          analysis.analysisStatus === AnalysisStatus.InProgress,
      },
      {
        label: <Text resource="analysisList.table.dropdown.viewSource" />,
        href: routes.settingsPage(analysis.analysisId),
        show: (!isViewer || (isViewer && isAnalysisOwner)) && !isSharingViewer,
      },
      {
        label: <Text resource="analysisList.table.dropdown.share" />,
        href: routes.sharingPage(analysis.analysisId),
        show: !isViewer && !isSharingViewer && state.listView !== AnalysisListView.SharedWithMe,
        isDisabled:
          (analysis.fileUploadStatus === FileUploadStatus.Failed &&
            analysis.analysisStatus === AnalysisStatus.Failed) ||
          analysis.analysisStatus === AnalysisStatus.Failed ||
          analysis.analysisStatus === AnalysisStatus.InProgress ||
          analysis.analysisStatus === AnalysisStatus.NotAnalyzed,
      },
      {
        label: <Text resource="analysisList.table.dropdown.deleteAnalysis" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
        show: !isViewer && (!isAnalyst || !isSharingViewer),
      },
    ];
  };

  const getAnalysisNameCell = (analysis: Analysis) => {
    return (
      <StyledNameCell isDisabled={analysisJobInProgress(analysis)}>
        {analysisJobInProgress(analysis) && (
          <StyledLoadingDotsContainer addMargin={analysisJobInProgress(analysis)}>
            <LoadingDots scaleSize={0.8} />
          </StyledLoadingDotsContainer>
        )}
        <TextTruncator value={analysis.name} id={analysis.analysisId} customWidth="85%" />
        {analysis.isShared && state.listView !== AnalysisListView.SharedWithMe && (
          <StyledIconBox>
            <Icon type={IconType.share} color={Color.gray20} size={16} />
          </StyledIconBox>
        )}
      </StyledNameCell>
    );
  };

  const dateInLocalTime = (analysis: Analysis) =>
    getLocalTime(analysis.lastUpdated).toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

  // Functions
  const sortAnalysis = (updatedSorting: FlexTableSorting) => {
    const sortColumn = tableInformation[updatedSorting.columnIndex].sortingParameter;
    dispatch(updateAnalysisListSorting({ sortColumn, sortOrder: updatedSorting.order }));
    dispatch(getAnalyses());
  };

  const changePage = (newPage: number) => {
    dispatch(setCurrentPage(newPage));
    dispatch(getAnalyses());
  };

  const updateSearchField = async () => {
    dispatch(setCurrentPage(1));
    dispatch(getAnalyses());
  };

  const handleRetryFailedImport = async (file: File) => {
    try {
      await uploadFile(state.retryUploadAnalysisId, file);
      await dispatch(getAnalyses());
    } catch (e) {
      dispatch(
        showToastError({
          message: {
            titleKey: "toast.uploadFileFailedTitle",
            captionKey: e.toString().includes("InvalidColumnHeaders")
              ? "toast.uploadMismatchCaption"
              : "toast.importMoreFailedCaption",
          },
        })
      );
    }
  };

  const handleChangeListView = (view: AnalysisListView) => {
    if (view !== state.listView) {
      dispatch(changeListView(view));
      dispatch(changeSelectedDropdownItem(view));
      queryParams.set("view", view);
      history.replace(location.pathname + location.hash + "?" + queryParams.toString());
      dispatch(getAnalyses());
    }
  };

  // Initial data fetch and setup
  useEffect(() => {
    // Get my Analyses count to remove myAnalyses if the user is a viewer
    dispatch(getMyAnalysesCount());
    const listViewParam = queryParams.get("view") as AnalysisListView;
    const listViewValid = Object.values(AnalysisListView).includes(listViewParam);
    const isViewerInEmptyMyAnalyses = state.myAnalysesCount === 0 && isViewer;

    const initialListView = (() => {
      if (isViewerInEmptyMyAnalyses) {
        return AnalysisListView.SharedWithMe;
      } else if (listViewValid) {
        return listViewParam;
      } else if (isViewer) {
        return AnalysisListView.SharedWithMe;
      }
      return AnalysisListView.MyAnalysis;
    })();

    if (!listViewValid || isViewerInEmptyMyAnalyses) {
      queryParams.set("view", initialListView);
      history.push(location.pathname + location.hash + "?" + queryParams.toString());
    }

    dispatch(setListView(initialListView));
    dispatch(
      setDropdownMenuContent(
        [
          isAnyAdmin(currentUser.roleType) && {
            label: <Text resource="analysisList.view.allAnalysis" />,
            value: AnalysisListView.AllAnalysis,
            isActive: initialListView === AnalysisListView.AllAnalysis,
          },
          !isViewerInEmptyMyAnalyses && {
            label: <Text resource="analysisList.view.myAnalysis" />,
            value: AnalysisListView.MyAnalysis,
            isActive: initialListView === AnalysisListView.MyAnalysis,
          },
          {
            label: <Text resource="analysisList.view.sharedWithMe" />,
            value: AnalysisListView.SharedWithMe,
            isActive: initialListView === AnalysisListView.SharedWithMe,
          },
        ].filter(Boolean)
      )
    );
    dispatch(getAnalyses());

    return () => {
      dispatch(clearState());
    };
  }, [dispatch]); // eslint-disable-line

  // Socket event listeners, get analyses on change.
  useEffect(() => {
    if (socket) {
      const handleAnalysisListUpdate = () => {
        dispatch(getAnalyses());
      };
      socket.on(SocketEvent.ChangedAnalysis, handleAnalysisListUpdate);
      return () => {
        socket.off(SocketEvent.ChangedAnalysis, handleAnalysisListUpdate);
      };
    }
  }, [socket, dispatch]);

  // Partial Renderings
  const getCountPill = (value: number, analysis: Analysis) => {
    if (!isAnalyzed(analysis.analysisStatus)) return null;
    if (value === null) {
      return (
        <StyledStatusPill>
          {value ?? getResource("analysisList.table.notAvailable")}
        </StyledStatusPill>
      );
    }
    return <StyledCountPill>{value}</StyledCountPill>;
  };

  const renderLastUpdatedFileUpload = (analysis: Analysis) => {
    switch (analysis.fileUploadStatus) {
      case FileUploadStatus.Uploading:
        return (
          <StyledStatusPill>
            <Text resource="recentUpdatesTable.status.uploading" />
          </StyledStatusPill>
        );
      case FileUploadStatus.Failed:
        return (
          <StyledFailedText>
            <Text resource="analysisList.table.importFailed" />
          </StyledFailedText>
        );
      case FileUploadStatus.ImportUploadError:
        return (
          <StyledFailedText>
            <Text resource="analysisList.table.firstStepFailed" />
          </StyledFailedText>
        );
      case FileUploadStatus.ImportValidationError:
        return (
          <StyledFailedText>
            <Text resource="analysisList.table.secondStepFailed" />
          </StyledFailedText>
        );
    }
  };

  const renderLastUpdatedCell = (analysis: Analysis) => {
    if (analysis.fileUploadStatus === FileUploadStatus.WaitingForInput) {
      return (
        <StyledStatusPill>
          <TextTruncator value={<Text resource="recentUpdatesTable.status.waitingForInput" />} />
        </StyledStatusPill>
      );
    }
    switch (analysis.analysisStatus) {
      case AnalysisStatus.Completed:
        return analysis.fileUploadStatus === FileUploadStatus.Completed ? (
          <TextTruncator value={dateInLocalTime(analysis)} />
        ) : (
          renderLastUpdatedFileUpload(analysis)
        );
      case AnalysisStatus.Uploading:
        return (
          <StyledStatusPill>
            <TextTruncator value={<Text resource="recentUpdatesTable.status.uploading" />} />
          </StyledStatusPill>
        );
      case AnalysisStatus.NotAnalyzed:
        return analysis.fileUploadStatus === FileUploadStatus.Completed ? (
          <StyledStatusPill>
            <TextTruncator value={<Text resource="recentUpdatesTable.status.notAnalyzedYet" />} />
          </StyledStatusPill>
        ) : (
          renderLastUpdatedFileUpload(analysis)
        );
      case AnalysisStatus.Failed:
        return (
          <StyledFailedText>
            <TextTruncator value={<Text resource="analysisList.table.analysisFailed" />} />
          </StyledFailedText>
        );
      default:
        return dateInLocalTime(analysis);
    }
  };

  // Check if the user is trying to view all analyses without permission, return error screen if so.
  if (
    !isAnyAdmin(currentUser.roleType) &&
    queryParams.get("view") === AnalysisListView.AllAnalysis
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      {state.isLoading && (
        <ListPageHeaderPlaceholder showCreateButton showFilterButton={false} applyMorePadding />
      )}
      <>
        <ActionConfirmationModal
          title={getResource("modal.deleteAnalysis.title")}
          caption={getResource("modal.deleteAnalysis.caption")}
          actionButtonLabel={getResource("button.delete")}
          isOpen={state.showDeleteConfirmation}
          onCancel={() => dispatch(setShowDeleteConfirmation(false))}
          onClickActionButton={() => dispatch(deleteSelectedAnalysis())}
          onClose={() => dispatch(setShowDeleteConfirmation(false))}
        />
        {!state.isLoading && (
          <>
            <StyledSearchBarRow>
              <ViewSelection
                dropdownMenuContent={state.dropdownMenuContent}
                handleSelectView={handleChangeListView}
              />
              <SearchRow
                onSearch={updateSearchField}
                currentCount={state.currentAnalysisCount}
                totalCount={state.totalAnalysisCount}
                dataType="searchBar.dataType.analysisList"
              />

              {currentUser.roleType !== RoleType.Viewer && (
                <ImportDataButton inputFileRef={inputFileRef} />
              )}
            </StyledSearchBarRow>

            {state.currentAnalysisCount > 0 && (
              <StyledPaginationContainer>
                <Pagination
                  currentPage={state.currentPage}
                  pageSize={DEFAULT_LIST_LIMIT}
                  currentItemsCount={state.currentAnalysisCount}
                  handlePageSelection={(num) => changePage(num)}
                />
              </StyledPaginationContainer>
            )}
          </>
        )}
        {state.totalAnalysisCount > 0 || state.isLoading ? (
          <FlexTable
            type={FlexTableType.Table}
            data={{
              headers: tableInformation.map((h) => h.headerName).concat(""),
              rows: state.analysisList?.map((analysis) => ({
                contentId: analysis.analysisId,
                data: [
                  <span key={`name-${analysis.analysisId}`}>{getAnalysisNameCell(analysis)}</span>,
                  state.listView !== AnalysisListView.MyAnalysis && (
                    <TextTruncator
                      value={analysis.owner}
                      id={analysis.analysisId}
                      key={`owner-${analysis.analysisId}`}
                      customWidth="85%"
                    />
                  ),
                  analysis.analysisStatus !== AnalysisStatus.NotAnalyzed ? (
                    <CategorizationTypeInfo
                      isPowerset={analysis.isPowerset}
                      family={analysis.family}
                      languages={analysis.languages}
                      key={`categorization-${analysis.analysisId}`}
                    />
                  ) : (
                    <></>
                  ),
                  <span key={`commentCount-${analysis.analysisId}`}>
                    {getCountPill(analysis.counts.analyzedCommentCount, analysis)}
                  </span>,
                  tableSize === FlexTableSize.Full && [
                    <span key={`topicCount-${analysis.analysisId}`}>
                      {getCountPill(analysis.counts.topicInsightCount, analysis)}
                    </span>,
                    <span key={`recommendationCount-${analysis.analysisId}`}>
                      {getCountPill(analysis.counts.recommendationCount, analysis)}
                    </span>,
                    <span key={`alertCount-${analysis.analysisId}`}>
                      {getCountPill(analysis.counts.alertCount, analysis)}
                    </span>,
                  ],
                  <StyledLastUpdatedCell key={`lastUpdated-${analysis.analysisId}`}>
                    {renderLastUpdatedCell(analysis)}
                  </StyledLastUpdatedCell>,
                ]
                  .flat()
                  .filter(Boolean),
                dotsMenuParams: {
                  rowId: analysis.analysisId,
                  isDeleting: state.isRowInDeleteMode,
                  currentEditedRowId: state.currentEditedRow?.analysisId,
                  menuContents: getDotsMenuContent(analysis).filter(({ show }) => show),
                  onDotMenuClick: () => dispatch(setCurrentEditedRow(analysis)),
                },
              })),
              columnWidths: getColumnWidths(),
            }}
            isLoading={state.isLoading}
            customStyles={{
              rows: {
                padding: "10px",
                backgroundColor: Color.white,
                rowHeight: "46px",
              },
            }}
            initialSorting={{
              columnIndex: tableInformation.findIndex(
                (h) => h.sortingParameter === state.sortColumn
              ),
              order: state.sortOrder,
            }}
            handleDoubleClickRow={(id) => onDoubleClickRow(id)}
            onSortingChange={sortAnalysis}
          />
        ) : (
          <EmptyState
            type={state.emptyStateType}
            handleClickCaptionLink={
              state.emptyStateType === EmptyStateType.noSearchResults
                ? () => dispatch(setSearchTerm(""))
                : () => inputFileRef?.current?.click()
            }
            image={isSharedWithMeView ? noAnalysisShared : emptyStateImage}
            titleKey={"emptyState.title"}
            captionKey={
              isSharedWithMeView
                ? "emptyState.noAnalysesShared.caption"
                : "emptyState.noAnalyses.caption"
            }
            linkKey={isSharedWithMeView ? undefined : "emptyState.link.noAnalyses"}
            customStyles={{
              imageWidth: "267px",
              imageHeight: "150px",
              display: state.emptyStateType === EmptyStateType.noListResults && "flex",
            }}
          />
        )}
      </>
      {/* for importing a failed analysis again */}
      <FileUploadInput
        inputFileRef={retryFailedImportInputRef}
        onSelectFile={handleRetryFailedImport}
        resetFileRef={false}
      />
      <FeatureFlag feature={Feature.importProgressModal}>
        <DataSourceModal
          fileUploadStatus={
            state.analysisList.find((analysis) => analysis.fileId === importModalFileId)
              ?.fileUploadStatus
          }
          importSuccessCallback={() => {
            history.push(
              routes.settingsPage(
                state.analysisList.find((analysis) => analysis.fileId === importModalFileId)
                  .analysisId
              )
            );
          }}
          modalType={DataSourceModalType.ImportFile}
        />
      </FeatureFlag>
    </div>
  );
};

const StyledStatusPill = styled.div`
  background-color: ${Color.blue20};
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 3px;
  display: inline-block;
`;

const StyledNameCell = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isDisabled }) => (isDisabled ? Color.gray30 : Color.gray50)};
`;

const StyledIconBox = styled.div`
  margin-left: auto;
  padding-right: 32px;
`;

const StyledLastUpdatedCell = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const StyledLoadingDotsContainer = styled.div<{ addMargin: boolean }>`
  ${({ addMargin }) =>
    addMargin &&
    css`
      margin-right: 10px;
    `}
  display: inline;
  height: 35px;
`;

const StyledFailedText = styled.div`
  color: ${Color.red50};
  font-weight: bold;
  mly-text {
    font-size: 14px;
  }
`;

const StyledCountPill = styled.div`
  background-color: ${Color.sky20};
  padding: 6px 9px;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
`;

const StyledSearchBarRow = styled(StyledTablePageHeader)`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const StyledPaginationContainer = styled.div`
  padding-bottom: 16px;
  position: sticky;
  position: -webkit-sticky;
  top: 105px;
  background: ${Color.white};
  z-index: ${ZIndexStackingContext.low + 1};
  width: 100%;
  box-sizing: content-box;
  div:only-child {
    margin-left: auto;
  }
`;
