import { useEffect, useState } from "react";
import { AnalysisStatus, FileUploadStatus, SocketEvent } from "@explorance/mly-types";
import { getFileById } from "services/files";
import { useAppSelector } from "store";
import { getAnalysisStatus } from "services/analysis";

type Props = {
  fileId?: number;
  fileUploadStatus?: FileUploadStatus;
  analysisStatus?: AnalysisStatus;
  analysisId?: number;
};

export const useRecentUpdateProgressBar = ({
  fileId,
  fileUploadStatus,
  analysisStatus,
  analysisId,
}: Props) => {
  const socket = useAppSelector((state) => state.wsStream.socket);

  const [analysisProgressPercentage, setAnalysisProgressPercentage] = useState<number>(0);
  const [analysisBuffer, setAnalysisBuffer] = useState<number>(0);
  const [analysisBufferProgress, setAnalysisBufferProgress] = useState<number>(0);

  const [uploadFileProgress, setFileUploadProgress] = useState<number>(0);
  const [uploadFileBuffer, setUploadFileBuffer] = useState<number>(0);
  const [uploadBufferProgress, setUploadBufferProgress] = useState<number>(0);

  // File upload effects
  useEffect(() => {
    if (uploadFileProgress > uploadBufferProgress + uploadFileBuffer && uploadFileBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setUploadBufferProgress((prev) => prev + uploadFileBuffer);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [uploadFileBuffer, uploadBufferProgress]); // eslint-disable-line

  useEffect(() => {
    if (!fileId || fileUploadStatus !== FileUploadStatus.Uploading) return;

    getFileById(fileId)
      .then((res) => {
        setUploadBufferProgress(res.data.progress);
        setUploadFileBuffer((res.data.progress - uploadBufferProgress) / 10);
      })
      .catch((err) => console.error(err));

    const polling = () => {
      const timer = setInterval(() => {
        getFileById(fileId)
          .then((res) => {
            if (
              res.data.uploadStatus === FileUploadStatus.WaitingForInput ||
              res.data.uploadStatus === FileUploadStatus.ImportUploadError ||
              res.data.uploadStatus === FileUploadStatus.Failed
            ) {
              clearInterval(timer);
              return;
            } else {
              setFileUploadProgress(res.data.progress);
              setUploadFileBuffer((res.data.progress - uploadBufferProgress) / 10);
            }
          })
          .catch((err) => console.error(err));
      }, 2000);
    };
    polling();
  }, [fileId]); // eslint-disable-line

  //  Analysis effects
  useEffect(() => {
    if (
      analysisProgressPercentage > analysisBufferProgress + analysisBuffer &&
      analysisBuffer > 0
    ) {
      const timeoutId = setTimeout(() => {
        setAnalysisBufferProgress((prev) => prev + analysisBuffer);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [analysisProgressPercentage, analysisBufferProgress]); // eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedAnalysisProgressBar, (data) => {
      setAnalysisProgressPercentage(data.percentage);
      setAnalysisBuffer((data.percentage - analysisBufferProgress) / 10);
    });

    return () => {
      socket.off(SocketEvent.ChangedAnalysisProgressBar, (data) => {
        setAnalysisProgressPercentage(data.percentage);
        setAnalysisBuffer((data.percentage - analysisBuffer) / 10);
      });
    };
  }, [socket]); // eslint-disable-line

  useEffect(() => {
    if (analysisStatus !== AnalysisStatus.InProgress) return;

    getAnalysisStatus(analysisId).then((data) => {
      if (data.data.status === AnalysisStatus.InProgress) {
        setAnalysisBuffer((data.data.percentage - analysisBuffer) / 10);
        setAnalysisBufferProgress(data.data.percentage);
      }
    });
  }, []); // eslint-disable-line

  return { uploadProgress: uploadBufferProgress, analysisProgress: analysisBufferProgress };
};
