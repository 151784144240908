import React, { useState, createRef, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { uploadFile } from "services/analysis";
import { isDegraded } from "utils/isDegraded";

import { FileUploadInput } from "components/_inputs/FileUpload";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import {
  setImportModalErrorFallbackAnalysisId,
  setImportModalFileId,
  setImportModalOpen,
} from "store/analysisSettings/dataSourceSlice";

type Props = {
  isDisabled?: boolean;
};

export const ImportMoreDataButton = ({ isDisabled }: Props) => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const [analysisContextState] = useContext(AnalysisContext);
  const [isImportError, setIsImportError] = useState<boolean>(false);

  const fileInputRef = createRef<HTMLInputElement>();

  const onImportFile = async (file: File) => {
    setIsImportError(false);
    try {
      const { data } = await uploadFile(analysisContextState.analysisDetails.id, file);
      dispatch(setImportModalOpen(true));
      dispatch(setImportModalFileId(data.fileId));
      dispatch(setImportModalErrorFallbackAnalysisId(analysisContextState.analysisDetails.id));
    } catch (e) {
      setIsImportError(true);
    }
  };

  return (
    <>
      <Button
        variant={ButtonVariant.outlineBlue}
        size={ButtonSize.ml}
        onClick={() => fileInputRef.current.click()}
        disabled={isDisabled || isDegraded(currentUser, analysisContextState)}
      >
        <Text resource="button.importMoreData" />
      </Button>
      <FileUploadInput
        inputFileRef={fileInputRef}
        onSelectFile={onImportFile}
        resetFileRef={isImportError}
      />
    </>
  );
};
