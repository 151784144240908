import React from "react";

import { Comment } from "pages/analysis/[id]/comments/_layouts/Comment";
import { CommentListPlaceholder } from "./Placeholder";
import { EmptyState } from "components/EmptyState";

import { EmptyStateType } from "ts/enums/emptyStateType";
import { Comment as CommentModel } from "ts/comments";
import { Pin } from "ts/pin";

type Props = {
  comments: CommentModel[];
  loading: boolean;
  searchField: string;
  selectionModeActive: boolean;
  checkCommentById: (id: number) => void;
  updateCommentPin: (ids: number[], pinData?: Pin) => void;
};

export const CommentList = ({
  comments,
  loading,
  searchField,
  checkCommentById,
  updateCommentPin,
  selectionModeActive,
}: Props) => {
  if (loading) return <CommentListPlaceholder />;
  if (comments.length === 0)
    return (
      <EmptyState
        type={EmptyStateType.noFilterResults}
        customStyles={{ marginTop: "0px", addGrayStyle: true }}
      />
    );

  return (
    <>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          searchField={searchField}
          selectionModeActive={selectionModeActive}
          checkCommentById={checkCommentById}
          updateCommentPin={updateCommentPin}
        />
      ))}
    </>
  );
};
