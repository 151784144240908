import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { SectionTitle } from "components/SectionTitle";
import { CollapsibleName } from "components/CollapsibleName";
import { DemographicFilterContainer } from "./DemographicFilterContainer";
import { TopicsFilter } from "../TopicFilterSelection";
import { ModelDropdown } from "components/ModelDropdown";
import { RangeSlider } from "components/RangeSlider";
import { ColumnFilterSection } from "./ColumnFilterSection";
import { NumberInput } from "components/_inputs/NumberInput";
import { PinnedCommentCategoriesFilter } from "./PinnedCommentCategoriesFilter";
import { Text } from "components/Text";

import { AnalysisContext } from "context/AnalysisContext";
import { getTitleFilterCount } from "utils/filters";
import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { getRecommendationTypes } from "utils/getRecommendationTypes";

import { AnalysisModel } from "ts/filters/analysisModel";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { ApiTopicNode } from "ts/topic";
import { Color } from "ts/enums/color";
import { Range } from "ts/range";
import { CommentsView, RecommendationType, SentimentType } from "@explorance/mly-types";
import { MAXIMUM_COMMENTS_THRESHOLD } from "assets/constants/maximumCommentsThreshold";
import { DEFAULT_ALERT_RANGE } from "assets/constants/defaultAlertRange";

type Props = {
  thresholdInputVal: number;
  setThresholdInputVal: Dispatch<SetStateAction<number | null>>;
  localSelectedModel: AnalysisModel;
  handleChangeSelectedModel: (id: number) => void;
  currentDemographicFilters: DemographicFilter[];
  setCurrentDemographicFilters: Dispatch<SetStateAction<DemographicFilter[]>>;
  localSelectedTopicNodes: ApiTopicNode[];
  setLocalSelectedTopicNodes: Dispatch<SetStateAction<ApiTopicNode[]>>;
  localSelectedColumnFilters?: string[];
  setLocalSelectedColumnFilters?: Dispatch<SetStateAction<string[]>>;
  localAlertRange?: Range;
  setLocalAlertRange?: Dispatch<SetStateAction<Range>>;
  localSelectedRecommendationTypes?: RecommendationType[];
  setLocalSelectedRecommendationTypes?: Dispatch<SetStateAction<RecommendationType[]>>;
  localSelectedSentimentTypes?: SentimentType[];
  setLocalSelectedSentimentTypes?: Dispatch<SetStateAction<SentimentType[]>>;
  localSelectedCategories?: string[];
  setLocalSelectedCategories?: Dispatch<SetStateAction<string[]>>;
  hasTopicsFilter?: boolean;
  hasRecommendationFilter?: boolean;
  hasSentimentFilter?: boolean;
  hasAlertRange?: boolean;
  isOpen: boolean;
  restrictedAccess?: boolean;
  isThresholdDisabled?: boolean;
};

export const FilterSelectionSection = ({
  thresholdInputVal,
  setThresholdInputVal,
  localSelectedModel,
  handleChangeSelectedModel,
  currentDemographicFilters,
  setCurrentDemographicFilters,
  localSelectedTopicNodes,
  setLocalSelectedTopicNodes,
  localSelectedColumnFilters,
  setLocalSelectedColumnFilters,
  localAlertRange,
  setLocalAlertRange,
  localSelectedRecommendationTypes,
  setLocalSelectedRecommendationTypes,
  localSelectedSentimentTypes,
  setLocalSelectedSentimentTypes,
  localSelectedCategories,
  setLocalSelectedCategories,
  hasTopicsFilter,
  hasRecommendationFilter,
  hasSentimentFilter,
  hasAlertRange,
  isOpen,
  restrictedAccess = false,
  isThresholdDisabled = false,
}: Props) => {
  const [state] = useContext(AnalysisContext);
  const [topicsExpanded, setTopicsExpanded] = useState<boolean>(false);

  const { getResource } = useResource();

  const location = useLocation();
  const { view } = useQueryParams();

  useEffect(() => {
    if (!isOpen) setTopicsExpanded(false);
  }, [isOpen]);

  const isTopicsExplorer = location.pathname.includes("topics");
  const isDashboard = location.pathname.includes("dashboard");

  const showCategorizedModelFilters =
    isDashboard ||
    isTopicsExplorer ||
    view === CommentsView.categorizedComments ||
    view === CommentsView.categorizedRecommendations;

  // show the topics filters only if the user is in the topics page or in the comments page with the categorized comments / recommendations views
  const showTopicsFilters =
    (isDashboard && hasTopicsFilter) ||
    isTopicsExplorer ||
    view === CommentsView.categorizedComments ||
    view === CommentsView.categorizedRecommendations;

  const showAlertRange =
    !isTopicsExplorer && state.analysisDetails.availableResults?.alerts === true;

  return (
    <>
      {showCategorizedModelFilters && (
        <StyledThresholdAndModelSelection addBorder={!(localAlertRange && showAlertRange)}>
          <StyledModelSelection>
            <SectionTitle mt={8} titleKey="sideDrawer.filters.model" />
            <ModelDropdown
              selectedModel={localSelectedModel}
              options={state.analysisModels}
              isDisabled={restrictedAccess}
              handleSelectMenuOption={handleChangeSelectedModel}
            />
          </StyledModelSelection>
          {!restrictedAccess && (
            <StyledThresholdInput>
              <SectionTitle mt={8} titleKey="sideDrawer.filters.threshold" />
              <NumberInput
                value={thresholdInputVal}
                minimum={0}
                maximum={MAXIMUM_COMMENTS_THRESHOLD}
                allowNull
                updateOnType
                hideIncrementButtons={false}
                disabled={isThresholdDisabled}
                styling={{
                  width: "100%",
                  border: `1px solid ${Color.sky50}`,
                  height: "36px",
                  padding: "6.5px 9px",
                  focusBorder: `1px solid ${Color.indigo50}`,
                }}
                handleChange={setThresholdInputVal}
              />
            </StyledThresholdInput>
          )}
        </StyledThresholdAndModelSelection>
      )}
      {hasAlertRange && showAlertRange && (
        <StyledAlertRangeSection>
          <SectionTitle mt={18} mb={18} titleKey="sideDrawer.filters.alertRange" />
          <RangeSlider
            currentMin={localAlertRange?.min || DEFAULT_ALERT_RANGE.min}
            currentMax={localAlertRange?.max || DEFAULT_ALERT_RANGE.max}
            onChange={setLocalAlertRange}
            width={425}
          />
        </StyledAlertRangeSection>
      )}
      {showTopicsFilters && (
        <>
          <SectionTitle mb={7} titleKey="sideDrawer.filters.topic" />

          <CollapsibleName
            name={getResource("filterPill.topics") + getTitleFilterCount(localSelectedTopicNodes)}
            isExpanded={topicsExpanded}
            onClick={() => setTopicsExpanded(!topicsExpanded)}
          />

          {topicsExpanded && (
            <TopicsFilter
              localSelectedModel={localSelectedModel}
              localSelectedTopicNodes={localSelectedTopicNodes}
              setLocalSelectedTopicNodes={setLocalSelectedTopicNodes}
              disabled={restrictedAccess}
            />
          )}
        </>
      )}
      {state.analysisDetails?.selectedColumns?.length > 1 && localSelectedColumnFilters && (
        <>
          <SectionTitle mb={7} titleKey="sideDrawer.filters.feedback" />
          <ColumnFilterSection
            options={state.analysisDetails.selectedColumns.map((column) => ({
              label: column,
              value: column,
            }))}
            currentFilters={localSelectedColumnFilters.map((column) => ({
              label: column,
              value: column,
            }))}
            restrictedAccess={restrictedAccess}
            updateFilters={(columns) =>
              setLocalSelectedColumnFilters(columns.map((column) => column.value))
            }
          />
        </>
      )}
      {hasRecommendationFilter && (
        <>
          <SectionTitle mb={7} titleKey="sideDrawer.filters.recommendation" />
          <ColumnFilterSection
            options={getRecommendationTypes().map((recommendation) => ({
              label: getResource(`statTab.${recommendation}`),
              value: recommendation,
            }))}
            currentFilters={localSelectedRecommendationTypes.map((recommendation) => ({
              label: getResource(`statTab.${recommendation}`),
              value: recommendation,
            }))}
            restrictedAccess={restrictedAccess}
            selectAllDisabled
            updateFilters={(filters) =>
              setLocalSelectedRecommendationTypes(
                filters.map((filter) => filter.value) as RecommendationType[]
              )
            }
          />
        </>
      )}
      {hasSentimentFilter && (
        <>
          <SectionTitle mb={7} titleKey="sideDrawer.filters.sentiment" />
          <ColumnFilterSection
            options={Object.keys(SentimentType).map((sentiment) => ({
              label: getResource(`statTab.${sentiment}`),
              value: sentiment,
            }))}
            currentFilters={localSelectedSentimentTypes.map((sentiment) => ({
              label: getResource(`statTab.${sentiment}`),
              value: sentiment,
            }))}
            restrictedAccess={restrictedAccess}
            selectAllDisabled
            updateFilters={(filters) =>
              setLocalSelectedSentimentTypes(
                filters.map((filter) => filter.value) as SentimentType[]
              )
            }
          />
        </>
      )}
      <SectionTitle mt={10} titleKey="sideDrawer.filters.demographics" />
      {state.availableDemographicFilters?.length > 0 ? (
        state.availableDemographicFilters.map((d) => (
          <DemographicFilterContainer
            demographic={d}
            key={d.id}
            currentFilterState={currentDemographicFilters}
            handleCurrentFilterState={setCurrentDemographicFilters}
            isOpen={isOpen}
          />
        ))
      ) : (
        <Text resource="sideDrawer.filters.noDemographicFilters" />
      )}

      {state.availableCategories.length > 0 && (
        <>
          <SectionTitle titleKey="sideDrawer.filters.pinnedComments" />
          <PinnedCommentCategoriesFilter
            localSelectedCategories={localSelectedCategories}
            setLocalSelectedCategories={setLocalSelectedCategories}
          />
        </>
      )}
    </>
  );
};

const StyledThresholdAndModelSelection = styled.div<{
  addBorder: boolean;
}>`
  width: 100%;
  color: ${Color.gray50};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ addBorder }) => addBorder && `1px solid ${Color.blue20}`};
  padding-bottom: ${({ addBorder }) => addBorder && "18px"};
`;

const StyledAlertRangeSection = styled.div`
  border-bottom: 1px solid ${Color.blue20};
  margin-bottom: 18px;
  height: 64px;
  max-width: 550px;
`;

const StyledModelSelection = styled.div`
  display: flex;
  flex-direction: column;
  width: 68%;
`;

const StyledThresholdInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;
